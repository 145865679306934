
.vep::before {
    content: "";
    width: 10px;
    height: 10px;
    margin-right: 3px;
    border: 1px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    /*background: lightslategrey;*/
}

.vep-lowest::before {
    background: green;
}
.vep-low::before {
    background: lightgreen;
}
.vep-moderate::before {
    background: yellow;
}
.vep-high::before {
    background: darkred;
}
.vep-failed::before {
    background: cornflowerblue;
}


